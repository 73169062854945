/* eslint-disable */
<template>
  <div id="menu">
    <Header></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Menu</h2>
                <ul class="breadcrumb-menu list-style">
                  <li><a href="">Home </a></li>
                  <li>Menu</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="product-details-wrap pb-100">
      <div class="container">
        <div class="row pt-100">
          <div class="col-lg-12">
            <iframe
              src="../../assets/img/hero/finales.pdf"
              width="100%"
              height="800px;"
            ></iframe>
          </div>
        </div>

        <div class="row pt-50">
          <a href="https://food.loopos.ca/?id=65d82147b3ee6659279e36da&slug=fatima-karahi-corner-svtgtv" class="btn">
          Order Online</a>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
/* eslint-disable */
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getMenu } from "@/store/api";

export default {
  name: "menu",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      activeClass: "",
      // fatimaSpecialKarahiItems: [
      //   {
      //     name: "Chicken Karahi (Full 1 Kg)",
      //     description:
      //       " Chicken cooked in karahi using our Special mixed Spices and our Signature gravy",
      //     price: "$31.99",
      //     img: require("@/assets/img/blog/chicken-karahi.jpg"),
      //   },
      //   {
      //     name: "Chicken Karahi (Half Kg)",
      //     description:
      //       " Chicken cooked in karahi using our Special mixed Spices and our Signature gravy",
      //     price: "$17.99",
      //     img: require("@/assets/img/blog/chicken-karahi.jpg"),
      //   },
      //   {
      //     name: "Chicken Karahi (Half Kg)",
      //     description:
      //       " Chicken cooked in karahi using our Special mixed Spices and our Signature gravy",
      //     price: "$17.99",
      //     img: require("@/assets/img/blog/chicken-karahi.jpg"),
      //   },
      // ],

      categories: "",
      categoryData: [],
      all: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    getMenu().then((res) => {
      console.log("====>", res);
      this.categories = res.data.items;
      var allitem = [];
      const combinedArray = Object.values(this.categories);
      for (var i = 0; i < combinedArray.length; i++) {
        combinedArray[i].forEach((value) => {
          allitem.push(value);
        });
      }
      this.all = allitem;

      // console.log(res.data['items']['Fast food'])
      // let arr = [];
      // arr = res.data.items;

      // Object.keys(arr).map((e) => {
      //   console.log(res.data.items[e][0].price);
      // });

      // // Object.keys(res.data.items).map((item) => (
      // //     console.log(item)
      // // ));
      // console.log("menu", res.data.items);
      // this.Items = res.data.items;
      // console.log("");
      // eslint-disable-next-line no-sequences
    });
  },

  methods: {
    allItem(categoryName) {
      this.categoryData = this.categories[categoryName];

      this.all = [];
      this.activeClass = "active show";
    },
    removeItem() {
      this.activeClass = "";
    },

    entireItems() {
      var allitem = [];
      const combinedArray = Object.values(this.categories);
      for (var i = 0; i < combinedArray.length; i++) {
        combinedArray[i].forEach((value) => {
          allitem.push(value);
        });
      }
      this.all = allitem;
    },
  },
};
</script>
<style>
#menu {
  height: auto !important;
}

.btn {
  background: red;
  width: 245px;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  color: white;
  font-size: 20px;
}
.btn:hover{
  background: black;
    width: 245px;
  margin: 0 auto;
  text-align: center;
  padding: 12px;
  color: white;
  font-size: 20px;
}
.heading-text {
  color: inherit;
  text-decoration: none;
  font-family: "nunito,sans-serif";
}
.text-para {
  font-size: 16px;
  line-height: 26px;
  color: #545454;
}
.price-text {
  color: #f41f31;
  font-weight: 600;
}
.product-tablist .nav-item {
  border: none;
  margin-right: 6px !important;
}
.product-tab-content {
  margin: 20px 0 0;
  border: 1px solid #eeeff2;
  padding: 5px 5px 5px 5px;
}
@media all and (max-width: 479px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block !important	;
  }
  #menu {
    height: 120px !important;
  }
  .product-tablist .nav-item {
    margin-right: 0;
    width: 100%;
    line-height: 1;
  }
}

@media screen and (max-width: 991px) {
  .wishlist-table table tbody tr td .wh_item {
    max-width: 100%;
    min-width: 304px;
  }
}
</style>
